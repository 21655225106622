.App {
  text-align: center;
}

.App-header {
  background-color: #541038;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.beatmapIdsInput {
  width: 50%;
  border-radius: 6px;
  background-color: #380C26;
}

.text-red {
  color: red !important;
}

.beatmapIdsInput p {
  width: 100%;
  height: 100%;
  color: white;
  font-size: 1.5rem;
  text-align: end;
}

textarea {
  width: 99%;
  height: 100%;
  background-color: #380C26;
  color: white;
  font-size: 1.5rem;
  border: none;
  resize: none;
  outline: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

button {
  height: 100%;
  background-color: #380C26;
  color: white;
  font-size: 1.5rem;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 1rem;
  border-radius: 6px;
  margin-top: 1rem;
}

button:disabled {
  cursor: not-allowed;
  background-color: #250819;
  color: grey;
}

button:hover {
  background-color: #3e0d29;
}

a {
  color: #380C26;
}

.socials {
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
}

.socials a {
  color: #ffffff;
  padding: 0.5rem;
  text-decoration: none;
}
